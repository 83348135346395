enum EUrlParams {
	id = ':id',
	engId = ':engId',
	diagramId = ':diagramId',
	planId = ':planId',
	oppModelId = ':oppModelId',
	optimizationId = ':optimizationId',
	workspaceId = ':workspaceId',
}

export default EUrlParams;
