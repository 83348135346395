import { lazy } from 'react';

import { ACCESS_LEVEL_TYPES } from 'shared/api/users.service';
import { ERoutes, ERoutesFullPath } from 'shared/enums/routes';
import AuthConsumer from 'shared/guards/auth.guard';

// Public pages
const PublicStoryboardDetails = lazy(() => import('pages/PublicStoryboardDetails'));
const PublicPage = lazy(() => import('pages/PublicPage'));
const PublicNotFoundPage = lazy(() => import('pages/PublicNotFoundPage'));
const PublicOptimizationFeedback = lazy(() => import('pages/PublicOptimizationFeedback'));

// corvus pages
const AccessDeniedPage = lazy(() => import('pages/AccessDeniedPage'));
const InactiveSubscriptionPage = lazy(() => import('pages/InactiveSubscriptionPage'));

const AuthorizedUser = lazy(() => import('pages/AuthorizedUser'));
const Login = lazy(() => import('pages/Login'));
const Registration = lazy(() => import('pages/Registration'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const Invitation = lazy(() => import('pages/Invitation'));
const UsersManagement = lazy(() => import('pages/UsersManagement'));
const Home = lazy(() => import('pages/Home'));
const UserManagement = lazy(() => import('pages/UserManagement'));
const SubscriptionManagement = lazy(() => import('pages/SubscriptionManagement'));
const BillingsManagement = lazy(() => import('pages/BillingManagement'));
const OrganizationManagement = lazy(() => import('pages/OrganizationManagment'));
const EngagementsPage = lazy(() => import('pages/Engagements'));
const IntegrationsPage = lazy(() => import('pages/IntegrationsPage'));
const ArchivedEngagementPage = lazy(() => import('pages/Engagements/ui/ArchivedEngagementPage'));
const Optimization = lazy(() => import('pages/Optimization'));
const OptimizationCategoriesManagement = lazy(
	() => import('pages/Optimization/ui/OptimizationCategoriesManagement'),
);
const OptimizationTagsManagement = lazy(
	() => import('pages/Optimization/ui/OptimizationTagsManagement'),
);
const OptimizationAIBuild = lazy(() => import('pages/Optimization/ui/OptimizationAIBuild'));

const Projects = lazy(() => import('pages/Projects'));
const EditProject = lazy(() => import('pages/Projects/ui/EditProject'));

const StoryboardDetails = lazy(() => import('pages/StoryboardDetails'));
const Storyboards = lazy(() => import('pages/Storyboards'));
const StoryboardsManageTagsProject = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageTagsProject'),
);
const StoryboardsManageTagsProjectModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageTagsProjectModule'),
);
const StoryboardsManageTagsStoryboardModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageTagsStoryboardModule'),
);
const StoryboardsManageCategoriesStoryboardModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageCategoriesStoryboardModule'),
);
const StoryboardsManageCategoriesProjectModule = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageCategoriesProjectModule'),
);
const StoryboardsManageCategoriesProject = lazy(
	() => import('pages/Storyboards/ui/StoryboardsManageCategoriesProject'),
);
const RetrospectivesPage = lazy(() => import('pages/Retrospectives'));
const EditActionPlanPage = lazy(() => import('pages/ActionPlan/ui/EditActionPlanPage'));
const ArchivedActionPlanPage = lazy(() => import('pages/ActionPlan/ui/ArchivedActionPlanPage'));
const ActionPlanTagsManagement = lazy(
	() => import('pages/ActionPlan/ui/ActionPlanTagsManagementPage'),
);
const ActionPlanCategoriesManagement = lazy(
	() => import('pages/ActionPlan/ui/ActionPlanCategoriesManagementPage'),
);
const ActionPlanManageIntegrationsPage = lazy(
	() => import('pages/ActionPlan/ui/ActionPlanManageIntegrationsPage'),
);
const AddLessonsLearned = lazy(() => import('pages/Retrospectives/ui/AddLessonsLearned'));
const EditLessonsLearned = lazy(() => import('pages/Retrospectives/ui/EditLessonsLearned'));
const Diagrams = lazy(() => import('pages/Diagrams'));
const DiagramsCategoriesManagement = lazy(
	() => import('pages/Diagrams/ui/DiagramsCategoriesManagement'),
);
const DiagramsTagsManagement = lazy(() => import('pages/Diagrams/ui/DiagramsTagsManagement'));
const EditDiagrams = lazy(() => import('pages/Diagrams/ui/EditDiagrams'));
const DiagramsAIBuild = lazy(() => import('pages/Diagrams/ui/DiagramsAIBuild'));
const OperatingModel = lazy(() => import('pages/OperatingModel'));
const ArchivedOperatingModelPage = lazy(
	() => import('pages/OperatingModel/ui/ArchivedOperatingModelPage'),
);
const OperatingModelAIBuild = lazy(() => import('pages/OperatingModel/ui/OperatingModelAIBuild'));
const OperatingModelCategoriesManagement = lazy(
	() => import('pages/OperatingModel/ui/OperatingModelCategoriesManagement'),
);
const OperatingModelTagsManagement = lazy(
	() => import('pages/OperatingModel/ui/OperatingModelTagsManagement'),
);

const Forecast = lazy(() => import('pages/Forecast'));
const ArchivedForecastPage = lazy(() => import('pages/Forecast/ui/ArchivedForecastPage'));
const ForecastTagsManagement = lazy(() => import('pages/Forecast/ui/ForecastTagsManagement'));
const ForecastCategoriesManagement = lazy(
	() => import('pages/Forecast/ui/ForecastCategoriesManagement'),
);
const ForecastDetailsPage = lazy(() => import('pages/Forecast/ui/ForecastDetailsPage'));
const PlanPage = lazy(() => import('pages/Plan'));
const PlanCategoriesManagement = lazy(() => import('pages/Plan/ui/PlanCategoriesManagement'));
const PlanTagsManagement = lazy(() => import('widgets/Plan/ui/PlanTagsManagement'));
const ActionPlanPage = lazy(() => import('pages/ActionPlan'));
const AIBuildPlanPage = lazy(() => import('pages/Plan/ui/AIBuildPlanPage'));
const ArchivedPlanPage = lazy(() => import('pages/Plan/ui/ArchivedPlanPage'));
const FileExplorerPage = lazy(() => import('pages/FileExplorer'));
const Workspaces = lazy(() => import('pages/Workspaces/Workspaces'));
const ArchivedWorkspacesPage = lazy(() => import('pages/Workspaces/ui/ArchivedWorkspacesPage'));
const WorkspacesTagsManagementPage = lazy(
	() => import('pages/Workspaces/ui/WorkspacesTagsManagementPage'),
);
const WorkspaceAIBuildPage = lazy(() => import('pages/Workspaces/ui/WorkspaceAIBuildPage'));
const WorkspaceBuildPage = lazy(() => import('pages/Workspaces/ui/WorkspaceBuildPage'));
const WorkspaceDetailsPage = lazy(() => import('pages/WorkspaceDetailsPage'));

//Add-ons
const IndustryReportsPage = lazy(() => import('pages/IndustryReports'));
const ProcessReportsPage = lazy(() => import('pages/ProcessReports'));

export type TRoutes = {
	name: string;
	path: string;
	Component: any;
	isAuth?: boolean;
	isHasAccess?: boolean;
	children?: TRoutes[];
	index?: boolean;
};

interface IDynamicObject {
	[key: string]: string;
}

export const useGetPublicRoutes = (): { publicRoutes: TRoutes[] } => {
	const publicRoutes: TRoutes[] = [
		{
			name: 'public',
			path: ERoutes.public,
			Component: PublicPage,
			isAuth: false,
			isHasAccess: true,
			children: [
				{
					name: 'public_not_found',
					path: ERoutes.public_not_found,
					Component: PublicNotFoundPage,
					isAuth: false,
					isHasAccess: true,
				},
				{
					name: 'public_storyboard',
					path: ERoutes.public_storyboard,
					Component: PublicStoryboardDetails,
					isAuth: false,
					isHasAccess: true,
				},
				{
					name: 'public_optimization_feedback',
					path: ERoutes.public_optimization_feedback,
					Component: PublicOptimizationFeedback,
					isAuth: false,
					isHasAccess: true,
				},
			],
		},
	];

	return { publicRoutes };
};

export const useGetRoutes = (): {
	routes: TRoutes[];
	routesMap: IDynamicObject;
} => {
	const { user } = AuthConsumer();

	const routes: TRoutes[] = [
		{
			name: 'access_denied_page',
			path: ERoutesFullPath.access_denied_page,
			Component: AccessDeniedPage,
			isAuth: true,
			isHasAccess: true,
		},
		{
			name: 'inactive_subscription',
			path: ERoutesFullPath.inactive_subscription,
			Component: InactiveSubscriptionPage,
			isAuth: true,
			isHasAccess: true,
		},
		{
			name: 'login',
			path: ERoutesFullPath.login,
			Component: Login,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'registration',
			path: ERoutesFullPath.registration,
			Component: Registration,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'invitation',
			path: ERoutesFullPath.invitation,
			Component: Invitation,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'reset_password',
			path: ERoutesFullPath.reset_password,
			Component: ResetPassword,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'change_password',
			path: ERoutesFullPath.change_password,
			Component: ChangePassword,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'change_password_secret',
			path: ERoutesFullPath.change_password_secret,
			Component: ChangePassword,
			isAuth: false,
			isHasAccess: true,
		},
		{
			name: 'authorized_user',
			path: ERoutesFullPath.authorized_user,
			Component: AuthorizedUser,
			isAuth: true,
			isHasAccess: true,
			children: [
				{
					name: 'home',
					path: ERoutesFullPath.home,
					Component: Home,
					isAuth: true,
					isHasAccess: user?.isCanBeViewHomePage,
				},
				{
					name: 'subscriptions_management',
					path: ERoutesFullPath.subscriptions_management,
					Component: SubscriptionManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewBillingManagement,
				},
				{
					name: 'billings_management',
					path: ERoutesFullPath.billings_management,
					Component: BillingsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewBillingManagement,
				},
				{
					name: 'users_management',
					path: ERoutesFullPath.users_management,
					Component: UsersManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewUserManagement,
				},
				{
					name: 'users_management_edit',
					path: ERoutesFullPath.users_management_edit,
					Component: UserManagement,
					isAuth: true,
					isHasAccess: true,
				},
				{
					name: 'organizations_management_edit',
					path: ERoutesFullPath.organizations_management_edit,
					Component: OrganizationManagement,
					isAuth: true,
					isHasAccess: true,
				},
				{
					name: 'integrations_management',
					path: ERoutesFullPath.integrations_management,
					Component: IntegrationsPage,
					isAuth: true,
					isHasAccess: user?.accessLevelType !== ACCESS_LEVEL_TYPES.COLLABORATOR,
				},
				{
					name: 'engagements',
					path: ERoutesFullPath.engagements,
					Component: EngagementsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewEngagementManagement,
				},
				{
					name: 'engagements_archived',
					path: ERoutesFullPath.engagements_archived,
					Component: ArchivedEngagementPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewEngagementManagement,
				},
				{
					name: 'optimization',
					path: ERoutesFullPath.optimization,
					Component: Optimization,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'optimization_categories_management',
					path: ERoutesFullPath.optimization_categories_management,
					Component: OptimizationCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'optimization_tags_management',
					path: ERoutesFullPath.optimization_tags_management,
					Component: OptimizationTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'optimization_ai_build',
					path: ERoutesFullPath.optimization_ai_build,
					Component: OptimizationAIBuild,
					isAuth: true,
					isHasAccess: user?.isCanBeCreateOptimizationPage,
				},
				{
					name: 'optimization_ai_report',
					path: ERoutesFullPath.optimization_ai_report,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'optimization_row_data',
					path: ERoutesFullPath.optimization_row_data,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOptimizationPage,
				},
				{
					name: 'project_portfolio_projects',
					path: ERoutesFullPath.project_portfolio_projects,
					Component: Projects,
					isAuth: true,
					isHasAccess:
						user?.isCanBeViewPortfoliosPage ||
						user?.isCanBeViewProgramsPage ||
						user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_edit',
					path: ERoutesFullPath.project_portfolio_projects_edit,
					Component: EditProject,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_storyboard_details',
					path: ERoutesFullPath.project_portfolio_projects_storyboard_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPortfolioGuidancePage,
				},
				{
					name: 'project_portfolio_projects_edit_storyboard_details',
					path: ERoutesFullPath.project_portfolio_projects_edit_storyboard_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'retrospectives',
					path: ERoutesFullPath.retrospectives,
					Component: RetrospectivesPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewReviewSolutionsPage || user?.isCanBeViewLessonLearnedPage,
				},
				{
					name: 'retrospectives_lessons_learned_add',
					path: ERoutesFullPath.retrospectives_lessons_learned_add,
					Component: AddLessonsLearned,
					isAuth: true,
					isHasAccess: user?.isCanBeCreateLessonLearnedPage,
				},
				{
					name: 'retrospectives_lessons_learned_edit',
					path: ERoutesFullPath.retrospectives_lessons_learned_edit,
					Component: EditLessonsLearned,
					isAuth: true,
					isHasAccess: user?.isCanBeEditLessonLearnedPage,
				},
				{
					name: 'action_plan',
					path: ERoutesFullPath.action_plan,
					Component: ActionPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_edit',
					path: ERoutesFullPath.action_plan_edit,
					Component: EditActionPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_archived',
					path: ERoutesFullPath.action_plan_archived,
					Component: ArchivedActionPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_tags_management',
					path: ERoutesFullPath.action_plan_tags_management,
					Component: ActionPlanTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_categories_management',
					path: ERoutesFullPath.action_plan_categories_management,
					Component: ActionPlanCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewActionPlansPage,
				},
				{
					name: 'action_plan_manage_integrations',
					path: ERoutesFullPath.action_plan_manage_integrations,
					Component: ActionPlanManageIntegrationsPage,
					isAuth: true,
					isHasAccess:
						process.env.REACT_APP_JIRA_FEATURE_TOGGLE === 'true'
							? user?.isCanBeViewActionPlansPage
							: false,
				},
				{
					name: 'storyboards',
					path: ERoutesFullPath.storyboards,
					Component: Storyboards,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'storyboards_tags_management',
					path: ERoutesFullPath.storyboards_tags_management,
					Component: StoryboardsManageTagsStoryboardModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'storyboards_categories_management',
					path: ERoutesFullPath.storyboards_categories_management,
					Component: StoryboardsManageCategoriesStoryboardModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'project_portfolio_projects_edit_tags_management',
					path: ERoutesFullPath.project_portfolio_projects_edit_tags_management,
					Component: StoryboardsManageTagsProject,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_edit_categories_management',
					path: ERoutesFullPath.project_portfolio_projects_edit_categories_management,
					Component: StoryboardsManageCategoriesProject,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProjectsPage,
				},
				{
					name: 'project_portfolio_projects_tags_management',
					path: ERoutesFullPath.project_portfolio_projects_tags_management,
					Component: StoryboardsManageTagsProjectModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPortfolioGuidancePage,
				},
				{
					name: 'project_portfolio_projects_categories_management',
					path: ERoutesFullPath.project_portfolio_projects_categories_management,
					Component: StoryboardsManageCategoriesProjectModule,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPortfolioGuidancePage,
				},
				{
					name: 'storyboard_details',
					path: ERoutesFullPath.storyboard_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewStoryBoards,
				},
				{
					name: 'diagrams',
					path: ERoutesFullPath.diagrams,
					Component: Diagrams,
					isAuth: true,
					isHasAccess: user?.isCanBeViewDiagramsPage,
				},
				{
					name: 'diagrams_categories_management',
					path: ERoutesFullPath.diagrams_categories_management,
					Component: DiagramsCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewDiagramsPage,
				},
				{
					name: 'diagrams_tags_management',
					path: ERoutesFullPath.diagrams_tags_management,
					Component: DiagramsTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewDiagramsPage,
				},
				{
					name: 'diagrams_edit',
					path: ERoutesFullPath.diagrams_edit,
					Component: EditDiagrams,
					isAuth: true,
					isHasAccess: user?.isCanBeViewDiagramsPage,
				},
				{
					name: 'diagrams_ai_build',
					path: ERoutesFullPath.diagrams_ai_build,
					Component: DiagramsAIBuild,
					isAuth: true,
					isHasAccess: user?.isCanBeEditDiagramsPage,
				},
				{
					name: 'operating_model',
					path: ERoutesFullPath.operating_model,
					Component: OperatingModel,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'operating_model_categories_management',
					path: ERoutesFullPath.operating_model_categories_management,
					Component: OperatingModelCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'operating_model_tags_management',
					path: ERoutesFullPath.operating_model_tags_management,
					Component: OperatingModelTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'operating_model_archived',
					path: ERoutesFullPath.operating_model_archived,
					Component: ArchivedOperatingModelPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'operating_model_ai_build',
					path: ERoutesFullPath.operating_model_ai_build,
					Component: OperatingModelAIBuild,
					isAuth: true,
					isHasAccess: user?.isCanBeEditOperatingModelPage,
				},
				{
					name: 'operating_model_ai_report',
					path: ERoutesFullPath.operating_model_ai_report,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'forecast',
					path: ERoutesFullPath.forecast,
					Component: Forecast,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_archived',
					path: ERoutesFullPath.forecast_archived,
					Component: ArchivedForecastPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_tags_management',
					path: ERoutesFullPath.forecast_tags_management,
					Component: ForecastTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_categories_management',
					path: ERoutesFullPath.forecast_categories_management,
					Component: ForecastCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'forecast_details',
					path: ERoutesFullPath.forecast_details,
					Component: ForecastDetailsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewForecastPage,
				},
				{
					name: 'plan',
					path: ERoutesFullPath.plan,
					Component: PlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_categories_management',
					path: ERoutesFullPath.plan_categories_management,
					Component: PlanCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_tags_management',
					path: ERoutesFullPath.plan_tags_management,
					Component: PlanTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_archived',
					path: ERoutesFullPath.plan_archived,
					Component: ArchivedPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_ai_build',
					path: ERoutesFullPath.plan_ai_build,
					Component: AIBuildPlanPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_edit',
					path: ERoutesFullPath.plan_edit,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewPlanPage,
				},
				{
					name: 'plan_categories_management',
					path: ERoutesFullPath.plan_categories_management,
					Component: PlanCategoriesManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'plan_tags_management',
					path: ERoutesFullPath.plan_tags_management,
					Component: PlanTagsManagement,
					isAuth: true,
					isHasAccess: user?.isCanBeViewOperatingModelPage,
				},
				{
					name: 'file_explorer',
					path: ERoutesFullPath.file_explorer,
					Component: FileExplorerPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewFileExplorerPage,
				},
				{
					name: 'industry_reports',
					path: ERoutesFullPath.industry_reports,
					Component: IndustryReportsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewIndustryReportsPage,
				},
				{
					name: 'industry_reports_details',
					path: ERoutesFullPath.industry_reports_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewIndustryReportsPage,
				},
				{
					name: 'process_reports',
					path: ERoutesFullPath.process_reports,
					Component: ProcessReportsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProcessReportsPage,
				},
				{
					name: 'process_reports_details',
					path: ERoutesFullPath.process_reports_details,
					Component: StoryboardDetails,
					isAuth: true,
					isHasAccess: user?.isCanBeViewProcessReportsPage,
				},
				{
					name: 'workspaces',
					path: ERoutesFullPath.workspaces,
					Component: Workspaces,
					isAuth: true,
					isHasAccess: user?.isCanBeViewWorkspacesPage,
				},
				{
					name: 'workspaces_archived',
					path: ERoutesFullPath.workspaces_archived,
					Component: ArchivedWorkspacesPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewWorkspacesPage,
				},
				{
					name: 'workspaces_tags_management',
					path: ERoutesFullPath.workspaces_tags_management,
					Component: WorkspacesTagsManagementPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewWorkspacesPage,
				},
				{
					name: 'workspace_ai_build',
					path: ERoutesFullPath.workspace_ai_build,
					Component: WorkspaceAIBuildPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewWorkspacesPage,
				},
				{
					name: 'workspace_build',
					path: ERoutesFullPath.workspace_build,
					Component: WorkspaceBuildPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewWorkspacesPage,
				},
				{
					name: 'workspace_edit',
					path: ERoutesFullPath.workspace_edit,
					Component: WorkspaceDetailsPage,
					isAuth: true,
					isHasAccess: user?.isCanBeViewWorkspacesPage,
				},
			],
		},
	];

	const routesMap: IDynamicObject = {};

	routes.forEach((route) => {
		if (route.hasOwnProperty('name')) {
			routesMap[route.name] = route.path;
		}
	});

	return { routes, routesMap };
};

function urlBuilder(
	name: ERoutes | ERoutesFullPath | string,
	params: IDynamicObject,
	searchParam?: string,
): string {
	let url = ''; // news/:id

	for (const key in params) {
		url = name.replace(`:${key}`, params[key]);
	}

	if (searchParam) {
		return `${url}${searchParam}`;
	}

	return url;
}

export const concatUrl = (values: string[], withStartSlash = false): string => {
	const url = values.join('/');
	if (withStartSlash) {
		return `/${url}`;
	}
	return url;
};

export { urlBuilder };
